import React, { Component, Fragment, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { generatePath, matchPath, withRouter } from 'react-router';
import AnalyticsStore from '@/analytics/store/AnalyticsStore';
import { GO_TO_COLLECTIONS_FROM_FOOTER, GO_TO_PRESENTATION_FROM_FOOTER, GO_TO_PRICING_FROM_FOOTER, GO_TO_PRIVACY_FROM_FOOTER, GO_TO_WORKSHOP } from '@/analytics/services/Analytics';
import { DRIVE_PAGE, DRIVE_PROJECT_PAGE, HOME_PAGE } from '@/common/constants/pages';
import Auth from '@/user/services/auth/Auth';
import Token from '@/user/services/token';
import AboutPopover from '@/common/components/Header/AboutPopover/AboutPopover';
import styles from './Header.less';
import AppStore from '../../stores/AppStore';
import UserStore from '../../../user/stores/UserStore';
import Link, { NAV, NONE } from '../Link/Link';
import Icon from '../Icon/Icon';
import CollectionNavItem from '@/common/components/Header/CollectionNavItem/CollectionNavItem';
import Button from '../Button/Button';
import { TYPE_JEWELRY } from '../../../product/constants/productTypes';
import Logo from '../Logo/Logo.tsx';
import NetBoxIndicator from '../../../netbox/components/NetBoxIndicator/NetBoxIndicator';
import { SCREEN_MD } from '../../constants/breakpoints';
import UserNavDropdown from '@/common/components/Header/UserNavDropdown/UserNavDropdown';
import MobileDrawer from '@/common/components/Header/MobileDrawer/MobileDrawer';
import DiamondsNavItem from '@/common/components/Header/DiamondsNavItem/DiamondsNavItem';
import ComparisonNavItem from '@/common/components/Header/ComparisonNavItem/ComparisonNavItem';

const ProductFormImport = lazy(() => import('../../../product/components/ProductForm/ProductFormImport/ProductFormImport'));

@inject('appStore', 'analyticsStore', 'userStore')
@withRouter
@observer
export default class Header extends Component {
  static propTypes = {
    appStore: PropTypes.instanceOf(AppStore).isRequired,
    analyticsStore: PropTypes.instanceOf(AnalyticsStore).isRequired,
    userStore: PropTypes.instanceOf(UserStore).isRequired,
    fixed: PropTypes.bool,
  };

  static defaultProps = {
    fixed: true,
  };

  state = {
    isImportModalOpened: false,
    isAboutPopoverOpened: false,
  };

  @observable
  isMobileMenuOpened = false;

  @action
  openMobileMenu = (e) => {
    e.preventDefault();
    e.target.closest('button')
      .blur();

    if (this.isMobileMenuOpened === true) {
      return;
    }

    this.isMobileMenuOpened = true;
  };

  @action
  closeMobileMenu = () => {
    this.isMobileMenuOpened = false;
  };

  closeAboutPopover = () => {
    this.setState({
      isAboutPopoverOpened: false,
    });
  };

  toggleAboutPopover = () => {
    this.setState(
      prevState => ({isAboutPopoverOpened: !prevState.isAboutPopoverOpened}),
    );
  };

  closeImportModal = () => {
    this.setState({
      isImportModalOpened: false,
    });
  };

  toggleImportModal = () => {
    this.setState(
      prevState => ({isImportModalOpened: !prevState.isImportModalOpened}),
    );
  };

  handleCollectionsClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_COLLECTIONS_FROM_FOOTER);
    this.closeMobileMenu();
    this.closeAboutPopover();
  };

  handlePresentationClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_PRESENTATION_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handleWorkshopClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_WORKSHOP);
    this.closeMobileMenu();
  };

  handlePricingClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_PRICING_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handlePrivacyPolicyClick = () => {
    this.props.analyticsStore.sendEventWithContext(GO_TO_PRIVACY_FROM_FOOTER);
    this.closeMobileMenu();
  };

  handleLogout() {
    try {
      navigator.sendBeacon(Auth.generateLogoutUrl());
    } catch (e) {
      // do nothing
    }

    Auth.logout();
  }

  redirectToLoginPage(e) {
    e.preventDefault();
    Auth.redirectToLoginPage();
  }


  renderUserLinks() {
    const isHomePage = this.props.location.pathname === HOME_PAGE;

    if (this.props.userStore.isAuthorized) {
      const user = this.props.userStore.user;

      return (
        <>
          <CollectionNavItem />

          {!isHomePage && <ComparisonNavItem/>}

          <UserNavDropdown user={user} logout={this.handleLogout} toggleImportModal={this.toggleImportModal}/>
        </>
      );
    }

    return (
      <>
        {!isHomePage && <ComparisonNavItem/>}
        <Link className="header__link header__link--desktop" href="#" onClick={this.redirectToLoginPage} appearance={NAV}>
          Log in
        </Link>
      </>
    );
  }

  render() {
    const isDemoCollectionsPageActive = this.props.location.pathname === '/collections';
    const isDriveContext = matchPath(this.props.location, {path: DRIVE_PAGE, exact: true})
    const isDriveProjectContext = matchPath(this.props.location, {path: DRIVE_PROJECT_PAGE, exact: true})
    const isLGDContext = this.props.appStore.isLGDContext && !isDemoCollectionsPageActive && !isDriveContext && !isDriveProjectContext
    const isJewelryContext = this.props.appStore.productTypeContext === TYPE_JEWELRY && !isDemoCollectionsPageActive && !isDriveContext && !isDriveProjectContext;
    const isNaturalContext = this.props.appStore.isNaturalContext && !isDemoCollectionsPageActive && !isDriveContext && !isDriveProjectContext;

    return (
      <>
        <header className={`${styles.header} ${this.props.fixed ? styles.headerFixed : ''} header--displayed`}>
          <div className={styles.headerContent}>
            <div className="header__hm">
              <Button color="transparent" onClick={this.openMobileMenu} withIcon aria-label="Header Menu Sections Control for Mobile Devices">
                <Icon name="hamburger"/>
              </Button>
            </div>

            <div className="header__left">
              <div className="header__logo">
                <Link to={Token.isAuthorized ? '/catalog/diamond-colorless' : '/'} appearance={NONE}>
                  <Logo/>
                </Link>
              </div>

              <div className="header__cache-proxy">
                <NetBoxIndicator/>
              </div>
            </div>

            {this.props.appStore.screenWidth < SCREEN_MD && (
              <MobileDrawer
                isOpened={this.isMobileMenuOpened}
                closeMobileMenu={this.closeMobileMenu}
                onCollectionClick={this.handleCollectionsClick}
                onPresentationClick={this.handlePresentationClick}
                onWorkshopClick={this.handleWorkshopClick}
                onPrivacyPolicyClick={this.handlePrivacyPolicyClick}
                onPricingClick={this.handlePricingClick}
                onLogout={this.handleLogout}
                redirectToLoginPage={this.redirectToLoginPage}
              />
            )}

            <div className="header__chapters" role="menu" aria-label="Header Menu Sections">
              {this.props.userStore.isAuthorized && (
                <Fragment>
                  <Link to={generatePath(DRIVE_PROJECT_PAGE)} className={`header-link header-link--desktop ${isDriveProjectContext ? styles.headerLinkActive : ''}`} role="menuitem" appearance={NONE}>
                    Projects
                  </Link>

                  <Link to={generatePath(DRIVE_PAGE)} className={`header-link header-link--desktop ${isDriveContext ? styles.headerLinkActive : ''}`} role="menuitem" appearance={NONE}>
                    Drive
                  </Link>
                </Fragment>
              )}

              <DiamondsNavItem
                isActive={isNaturalContext}
                title="Natural Diamonds"
                colorlessLink="/catalog/diamond-colorless"
                fancyLink="/catalog/diamond-fancy"
                roughLink="/catalog/rough"
              />

              <DiamondsNavItem
                isActive={isLGDContext}
                title="Lab-Grown Diamonds"
                colorlessLink="/catalog/lgd-colorless"
                fancyLink="/catalog/lgd-fancy"
                roughLink="/catalog/lgd-rough"
                navClassName={styles.headerLinkLGD}
              />

              <Link to="/catalog/jewelry" className={`header-link header-link--desktop ${isJewelryContext ? styles.headerLinkActive : ''}`} role="menuitem" appearance={NONE}>
                Jewelry
              </Link>
              <Link
                className={`header-link header-link--desktop ${isDemoCollectionsPageActive ? styles.headerLinkActive : ''} hide-on-small-screens`}
                onClick={this.handleCollectionsClick}
                to="/collections"
                role="menuitem"
                appearance={NONE}
              >
                Demo Collections
              </Link>
              <AboutPopover
                isOpened={this.state.isAboutPopoverOpened}
                onCollectionsClick={this.handleCollectionsClick}
                onPresentationClick={this.handlePresentationClick}
                onWorkshopClick={this.handleWorkshopClick}
                onPricingClick={this.handlePricingClick}
                onPrivacyPolicyClick={this.handlePrivacyPolicyClick}
                close={this.closeAboutPopover}
              >
                <div className="header-link header-link--desktop header-link--dropdown" onClick={this.toggleAboutPopover}>
                  About <Icon name="arrow-lt-down" className={`header-link__triangle ${this.state.isAboutPopoverOpened ? 'header-link__triangle--opened' : ''}`} />
                </div>
              </AboutPopover>
            </div>
            <div className="header__links">{this.renderUserLinks()}</div>

            <Suspense fallback={null}>
              <ProductFormImport
                onSubmit={() => {
                }}
                onClose={this.closeImportModal}
                isOpened={this.state.isImportModalOpened}
              />
            </Suspense>
          </div>
        </header>
      </>
    );
  }
}
