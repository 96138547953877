import CutwiseAPIClient, { METHOD_COPY, METHOD_DELETE, METHOD_GET, METHOD_HEAD, METHOD_LINK, METHOD_PATCH, METHOD_POST, METHOD_PUT, METHOD_UNLINK, } from '../../common/network/CutwiseAPIClient';
import ConsoleLog from '../../common/network/middleware/ConsoleLog';
const MERGE_TYPE = 'vector';
export const PATH_COLLECTIONS = 'api/v2/collections';
export default class CollectionsAPI {
    create(data) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_POST).body(data).path(PATH_COLLECTIONS);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    fetch(id, shouldDumpResponse = false) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(`${PATH_COLLECTIONS}/${id}`)
            .suppressErrorNotifications();
        const query = qb.getQuery();
        if (shouldDumpResponse) {
            query.addResponseMiddleware(ConsoleLog);
        }
        return CutwiseAPIClient.sendQuery(query);
    }
    fetchOwned(type) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_GET)
            .path(`${PATH_COLLECTIONS}/my`)
            .addQueryStringParam('type', type);
        if (type) {
            qb.addQueryStringParam('type', type);
        }
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    wishAll(id, ids) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_LINK)
            .path(`${PATH_COLLECTIONS}/${id}/products`)
            .addHeader('link', ids);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    update(id, boardParams) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_PATCH)
            .path(`${PATH_COLLECTIONS}/${id}`)
            .body(boardParams);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    copy(id) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_COPY).path(`${PATH_COLLECTIONS}/${id}`);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    addProductToCollection(product, collection) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_LINK)
            .path(`${PATH_COLLECTIONS}/${collection.id}/products`)
            .addHeader('link', [product.id]);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    remove(id) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_DELETE).path(`${PATH_COLLECTIONS}/${id}`);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    removeProductFromCollection(product, collection) {
        const qb = CutwiseAPIClient.createQueryBuilder()
            .method(METHOD_UNLINK)
            .path(`${PATH_COLLECTIONS}/${collection.id}/products`)
            .addHeader('link', [product.id]);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    updateManualSorting(id, itemsOrder) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_PUT).path(`${PATH_COLLECTIONS}/${id}/sort`).body({
            data: itemsOrder,
            type: MERGE_TYPE,
        });
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
    touchCollection(c) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_HEAD).path(`${PATH_COLLECTIONS}/${c.id}`);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
