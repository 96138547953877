// extracted by mini-css-extract-plugin
var _1 = "qjPlGcJnewAsQIugpoqM";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#4A90E2";
var _c = "#ffffff";
var _d = "wCcHzMLD3NzDdZgamTQK";
var _e = "BY9hagSGHLDrVCXpPm1Z";
var _f = "biLyfYFePAF0is5w_f3a";
var _10 = "K9PNKkuuxDoxBomW9jRG";
var _11 = "1200px";
var _12 = "992px";
var _13 = "1366px";
var _14 = "768px";
var _15 = "1475px";
var _16 = "576px";
var _17 = "1600px";
var _18 = "320px";
var _19 = "2500px";
var _1a = "KEVdIrrNfQEadjLIMULv";
var _1b = "EmptinBUjP0Y5VA3CgJm";
export { _1 as "app", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorRegularLinks", _c as "colorWhite", _d as "e2eMode", _e as "product-block-grid", _f as "product-block-subgrid", _10 as "reset-button", _11 as "screenlg", _12 as "screenmd", _13 as "screenml", _14 as "screensm", _15 as "screenxlg", _16 as "screenxs", _17 as "screenxxlg", _18 as "screenxxs", _19 as "screenxxxlg", _1a as "sf-toolbar", _1b as "single-page-responsive-font-size" }
export default { "app": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorRegularLinks": _b, "colorWhite": _c, "e2eMode": _d, "product-block-grid": _e, "product-block-subgrid": _f, "reset-button": _10, "screenlg": _11, "screenmd": _12, "screenml": _13, "screensm": _14, "screenxlg": _15, "screenxs": _16, "screenxxlg": _17, "screenxxs": _18, "screenxxxlg": _19, "sf-toolbar": _1a, "single-page-responsive-font-size": _1b }
